export default {
  primary: '#232323',
  semiBlack: '#050505',
  black: '#000000',

  darkgrey: '#323232',
  darkBlueGrey: '#2D3942',
  grey: '#8F9193',
  blueGrey: '#828F9F',
  lightGrey: '#505050',
  brightGrey: '#EFF0F2',
  white: '#ffffff',
  coldGrey: '#B1C3CD',
  newGrey: '#2A3943',

  brand: '#D22222',
  red: '#BB2B22',
  deepRed: '#9A231C',
  shallowRed: '#CF424C',
  lightRed: '#C7232E'
};
